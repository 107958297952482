<template>
  <font-awesome-icon
    v-if="defaultImage && (!imageSrc || onImageError)"
    :class="['text-muted', defaultClass]"
    :icon="defaultIcon"
    :title="defaultTitle"
  />
  <img
    v-else
    :class="imageClass"
    :src="imageSrc"
    :style="imageStyle"
    :alt="imageAlt"
    :title="imageTitle"
    @error="onImageLoadError"
  />
</template>

<script>
export default {
  props: {
    imageSrc: {
      type: String,
      required: true,
    },
    imageAlt: {
      type: String,
      required: true,
    },
    imageClass: {
      type: String,
    },
    imageTitle: {
      type: String,
    },
    imageStyle: {
      type: String,
    },
    defaultClass: {
      type: String,
    },
    defaultImage: {
      type: Boolean,
      default: true,
    },
    defaultIcon: {
      type: String,
      default: 'camera',
    },
    defaultTitle: {
      type: String,
    },
  },
  data() {
    return {
      onImageError: false,
    };
  },
  methods: {
    onImageLoadError() {
      this.$emit('imgLoadError');
      this.onImageError = true;
    },
  },
  computed: {
    src() {
      return `${this.$configuration.cdn}/api/img/${this.format}/${this.data}`;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/tools';
@import '~@/assets/styles/settings';

.barcode {
  display: block;
  max-width: rem(350px);
  margin: 0 auto;
}
</style>
