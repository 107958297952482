<template>
  <div class="wc-pricing font-size-xs" :class="{ 'wc-scan-pricing': isScanMode }">
    <div
      v-if="hasPrice && !item.id.includes('ASC')"
      ref="actualPrice"
      class="font-weight-bold"
      :class="{ 'wc-pricing__actual--sale': isOnSale }"
      :aria-describedby="`${item.id}-priceDesc`"
    >
      {{ actualPrice }}
      <span class="sr-only" :id="`${item.id}-priceDesc`">{{
        isOnSale ? $t('salePrice') : $t('productPrice')
      }}</span>

      <WCToolTip
        v-if="isDiscountedInCart"
        target="actualPrice"
        position="top-start"
        class="wc-pricing__tooltip"
      >
        <div class="whitespace-pre">{{ getToolTipText }}</div>
      </WCToolTip>
      <font-awesome-icon v-if="isDiscountedInCart" icon="circle-question" />
    </div>
    <div v-else-if="!isSoldByAvgWeight" ref="noPrice">
      {{ $t('seePriceInCart') }}
    </div>
    <div v-if="hasPrice && isOnSale" class="text-muted" :aria-describedby="`${item.id}-priceText`">
      <s>{{ suggestedPrice }}</s>
      <span class="sr-only" :id="`${item.id}-priceText`">{{ $t('productPrice') }}</span>
    </div>
    <div
      v-if="hasPrice && isSoldByAvgWeight"
      class="font-weight-bold wc-sold-by-avg-weight"
      :aria-describedby="`${item.id}-avgPriceText`"
    >
      {{ avgPerUnitPrice }}
      <span class="sr-only" :id="`${item.id}-avgPriceText`">{{ $t('averagePerUnitPrice') }}</span>
    </div>
    <div v-if="!hasPrice" class="font-weight-bold">
      {{ $t('callForPrice') }}
    </div>
    <div v-if="embeddedPrice" class="font-weight-bold">
      {{ embeddedPrice | currency }}{{ '/ea' }}
    </div>
    <div v-if="price" class="font-weight-bold">{{ price | currency }}{{ '/ea' }}</div>
  </div>
</template>

<script>
import { RIBBON_CONFIG } from '../../constants/PromoRibbonConstants';
import { currency } from '../../utils/formatters';
import WCToolTip from '../WCToolTip/WCToolTip.vue';

export default {
  components: { WCToolTip },
  props: {
    item: {
      type: Object,
    },
    price: {
      type: Number,
    },
    embeddedPrice: {
      type: Number,
    },
    isScanMode: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isOnSale() {
      return (
        this.item.suggestedPrice != null &&
        this.item.actualPrice / this.item.actualPriceDivider < this.item.suggestedPrice
      );
    },
    hasPrice() {
      return this.item?.actualPrice >= 0;
    },
    isSoldByAvgWeight() {
      return this.item?.averageWeight != null && this.item?.averageWeight >= 0;
    },
    isDividedPrice() {
      return this.item?.actualPriceDivider && this.item?.actualPriceDivider > 1;
    },
    isDiscountedInCart() {
      if (this.isScanMode) {
        return (
          RIBBON_CONFIG.DISCOUNT_NAME_RIBBON.canDisplay(this.item) ||
          RIBBON_CONFIG.SALE_AMOUNT_RIBBON.canDisplay(this.item)
        );
      }
      return this.item?.autoDiscount || this.item?.buyDown;
    },
    unit() {
      if (this.item?.weightProfile && this.item?.weightProfile.abbrv) {
        return this.item.weightProfile.abbrv;
      }
      return 'ea';
    },
    actualPrice() {
      const potentialDivider = this.isDividedPrice ? `${this.item.actualPriceDivider}/` : '';
      const actualPrice = currency(this.item.actualPrice);
      const potentialUnit = !this.isDividedPrice ? `/${this.unit}` : '';
      const computedProductPrice = `${potentialDivider}${actualPrice}${potentialUnit}`;
      this.$emit('actualPrice', computedProductPrice);
      return computedProductPrice;
    },
    suggestedPrice() {
      return `${currency(this.item.suggestedPrice)}/${this.unit}`;
    },
    avgPerUnitPrice() {
      const price = `${currency(this.item.averageWeight * this.item.actualPrice)}`;
      return `${price}/ea avg`;
    },
    getToolTipText() {
      let discountText = '';
      if (RIBBON_CONFIG.DISCOUNT_NAME_RIBBON.canDisplay(this.item)) {
        discountText += `${RIBBON_CONFIG.DISCOUNT_NAME_RIBBON.getTextContent(this.item)}\n`;
      }

      if (RIBBON_CONFIG.SALE_AMOUNT_RIBBON.canDisplay(this.item)) {
        discountText += `${RIBBON_CONFIG.SALE_AMOUNT_RIBBON.getTextContent(this.item)}`;
      }

      return this.isScanMode ? discountText : this.$t('discountAppliedInCart');
    },
  },
};
</script>

<style scoped lang="scss">
@import '~@/assets/styles/tools';
@import '~@/assets/styles/settings';

.wc-pricing {
  line-height: normal;

  .wc-pricing__actual--sale {
    color: var(--info, $info);
  }

  .wc-pricing__tooltip {
    font-size: $font-size-2xs;
  }
}

.wc-scan-pricing {
  transform: translate3d(0, 0, 0);
}

.whitespace-pre {
  white-space: pre;
}
</style>
