<template>
  <WCSimpleToast
    :title="title"
    :variant="variant"
    :close="close"
    :dismiss="dismiss"
    :startTimeout="startTimeout"
  >
    {{ $t('verifyOrderTypeFor', { item: this.item.name }) }}
    <WCOrderTypesAddToCartDropdown
      :item="item"
      :showMobileCustomize="true"
      :orderTypes="orderTypes"
      :describedBy="'WC Add To Order Type Cart Toast'"
      @addToCart="startTimeout(10000)"
      @decrement="startTimeout(10000)"
      @click.native="isMadeToOrderItem && close()"
    />
    <button class="btn btn-primary float-right m-2" @click="close()">
      {{ $t('done') }}
    </button>
  </WCSimpleToast>
</template>

<script>
import OrderTypesMixin from '@/modules/orderTypes/mixins/OrderTypesMixin';
import WCOrderTypesAddToCartDropdown from '@/modules/orderTypes/components/WCOrderTypesAddToCartDropdown/WCOrderTypesAddToCartDropdown.vue';
import SimpleToastMixin from '@/modules/toasts/mixins/SimpleToastMixin';
import WCSimpleToast from '../WCSimpleToast/WCSimpleToast.vue';
import ToastMixin from '../../mixins/ToastMixin';

export default {
  name: 'WCAddToOrderTypeCartToast',
  components: { WCSimpleToast, WCOrderTypesAddToCartDropdown },
  mixins: [ToastMixin, OrderTypesMixin, SimpleToastMixin],
  props: {
    item: {
      type: Object,
      required: true,
    },
    orderTypes: {
      type: Array,
      required: true,
    },
  },
  computed: {
    isMadeToOrderItem() {
      return this.item.hasModifiers || this.item.hasLinkedItems;
    },
  },
};
</script>
