import axios from 'axios';
import { mapActions, mapGetters } from 'vuex';
import AnalyticsService from '@/services/analytics.service';
import TrackableEventConstants from '@/constants/TrackableEventConstants';

export default {
  computed: {
    ...mapGetters({
      getCartOrderType: 'cart/getCartOrderType',
      getUser: 'user/getUser',
      isAcoMode: 'user/isCustomerModeScan',
    }),
    visibleOrderTypes() {
      return this.$configuration.orderTypes?.filter(orderType => !orderType.hidden);
    },
    activeOrderType() {
      return this.orderTypeObjectFromId(this.getCartOrderType);
    },
    orderTypesMenu() {
      const mappedOrderTypes = this.visibleOrderTypes?.map(orderType => {
        return {
          children: orderType.menu ? orderType.menu.children : null,
          name: orderType.name,
          id: orderType.id,
        };
      });

      const orderTypesMenu = {
        children: mappedOrderTypes,
        name: 'Order Types',
      };

      return orderTypesMenu;
    },
    searchableOrderTypes() {
      return this.visibleOrderTypes
        ?.filter(orderType => !orderType.giftCard)
        ?.map(orderType =>
          orderType.id ? { ...orderType, id: `WOT-${orderType.id}` } : undefined,
        );
    },
  },
  methods: {
    ...mapActions({
      reloadCart: 'cart/reload',
      setCurrentCart: 'cart/setCurrentCart',
    }),
    orderTypeHomeForOrderType(orderType) {
      if (!orderType) return `/`;

      return `/orders/${orderType.id}`;
    },
    async setActiveOrderType(orderType) {
      try {
        if (orderType) {
          const response = await axios.get(`/api/ot/${orderType.id}`);
          this.orderType = response.data;
          await this.reloadCart();

          if (this.orderType.giftCard) {
            const orderTypeResponse = await axios.get(`api/ot/${this.orderType.id}/cart`);
            await this.setCurrentCart(orderTypeResponse.data);
          }

          if (orderType) {
            AnalyticsService.track(TrackableEventConstants.OrderTypeSwitched, {
              id: orderType.id,
              customerId: this.getUser?.sub,
              name: orderType.name,
              ads: orderType.ads,
            });
          }
        }
      } catch (error) {
        this.loading = false;
        this.loadFailedError = error;
        console.error(error);
      }
    },
    orderTypeObjectFromId(orderTypeIdParam) {
      if (!orderTypeIdParam) return null;

      let orderTypeId = orderTypeIdParam;
      if (orderTypeId.startsWith('WOT-')) {
        orderTypeId = orderTypeId.substring(4);
      }

      return this.$configuration.orderTypes?.find(orderType => orderType.id === orderTypeId);
    },
    isItemEligibleForOrderType(item, orderType) {
      if (!item || !orderType || !this.$configuration.orderTypes) return false;

      const menu = orderType.menu;
      const itemGroupIds = item.itemGroups;
      return itemGroupIds?.find(itemGroupId => this.findItemGroupInMenu(menu, itemGroupId));
    },
    eligibleOrderTypesForItem(item) {
      const eligibleOrderTypes = [];

      this.visibleOrderTypes?.forEach(orderType => {
        if (this.isItemEligibleForOrderType(item, orderType)) {
          eligibleOrderTypes.push(orderType);
        }
      });

      return eligibleOrderTypes;
    },
    eligibleInactiveOrderTypesForItem(item) {
      return this.eligibleOrderTypesForItem(item).filter(
        orderType => orderType.id !== this.activeOrderType?.id && !orderType.listOnly,
      );
    },
    /**
     * Attempts to recursively search a menu for a provided item group ID.
     * @param {Object} menu Menu object that represents an Item Group Menu or Item Group
     * @param {String} itemGroupId Typed ID for the item group we are trying to find
     * @returns The Item Group ID if the item group was found in the menu, or undefined
     */
    findItemGroupInMenu(menu, itemGroupId) {
      if (!menu || !itemGroupId) return undefined;

      let foundMenuItem;
      // Loop through the values on the menu object
      Object.values(menu).some(menuItem => {
        // If this value matches the item group ID, we found it
        if (menuItem === itemGroupId) {
          foundMenuItem = menuItem;
          return true;
        }
        // If this value is an object, recursively search it
        if (typeof menuItem === 'object') {
          foundMenuItem = this.findItemGroupInMenu(menuItem, itemGroupId);
          return foundMenuItem !== undefined;
        }
        return false;
      });

      return foundMenuItem;
    },
    navigateToOrderTypeHome(orderTypeId) {
      if (!orderTypeId) return;

      this.$router.push({
        name: 'Shopping by Order Type',
        params: { id: orderTypeId },
      });
    },
    isItemListOnly(item) {
      if (this.isAcoMode) {
        return false;
      }
      // Treat disabled cart the same as list only
      if (!this.$configuration.cartEnabled) {
        return true;
      }
      if (!this.$configuration.orderTypesEnabled) {
        return false;
      }
      if (this.isItemEligibleForOrderType(item, this.activeOrderType)) {
        if (this.activeOrderType?.listOnly) {
          return true;
        }
        return false;
      }
      if (this.activeOrderType?.listOnly) {
        return true;
      }
      if (this.isItemExclusivelyListOnly(item)) {
        return true;
      }
      return false;
    },
    isItemExclusivelyListOnly(item) {
      if (this.isAcoMode) {
        return false;
      }
      // If the cart is disabled, all items are list only
      if (!this.$configuration.cartEnabled) {
        return true;
      }
      const orderTypes = this.eligibleOrderTypesForItem(item);
      for (let otIndex = 0; otIndex < orderTypes.length; otIndex += 1) {
        const orderType = orderTypes[otIndex];
        if (!orderType?.listOnly) {
          return false;
        }
      }
      return true;
    },
  },
};
