<template>
  <Dashboard>
    <template v-slot:breadcrumbs>
      <WCBreadcrumb :crumbs="breadcrumbs" @selected="breadcrumbs[breadcrumbs.length - 1]" />
    </template>

    <template v-slot:title>
      {{ $t('employeeDashboard') }}
    </template>

    <template v-slot:sidebar>
      <WCEmployeeSidebar />
    </template>
  </Dashboard>
</template>

<script>
import Dashboard from '@/layouts/components/Dashboard/Dashboard.vue';
import WCEmployeeSidebar from '@/modules/employee/components/WCEmployeeSidebar/WCEmployeeSidebar.vue';
import WCBreadcrumb from '@/components/WCBreadcrumb/WCBreadcrumb.vue';

export default {
  name: 'EmployeeDashboard',
  components: { WCEmployeeSidebar, Dashboard, WCBreadcrumb },
  computed: {
    breadcrumbs() {
      return this.$route.meta.breadcrumbs;
    },
  },
};
</script>

<style scoped></style>
