import {
  faCartShopping,
  faChevronDown,
  faChevronUp,
  faSearch,
  faTimesCircle,
  faTimes,
  faMagnifyingGlass,
  faXmarkCircle,
  faXmark,
  faEye,
  faEyeSlash,
  faFilter,
  faTrash,
  faMinus,
  faPlus,
  faChevronRight,
  faChevronLeft,
  faStore,
  faEnvelope,
  faHouse,
  faCircleQuestion,
  faCheck,
  faArrowsRotate,
  faPhone,
  faPhoneAlt,
  faTruckFast,
  faCamera,
  faStar,
  faBell,
  faBug,
  faCircleExclamation,
  faTriangleExclamation,
  faUser,
  faCircleCheck,
  faBars,
  faSignal,
  faListCheck,
  faMapMarkedAlt,
  faBarcode,
  faQrcode,
  faHeart,
  faAddressCard,
  faMapMarkerAlt,
  faList,
  faArrowLeft,
  faBookmark,
  faMoneyBillWave,
  faPencilAlt,
  faSquareCheck,
  faMagic,
  faLeaf,
  faGift,
  faBackspace,
  faEllipsis,
  faPaperPlane,
  faRightLeft,
  faClock,
  faSliders,
  faGlobe,
  faRightToBracket,
  faAddressBook,
  faClockRotateLeft,
  faDoorOpen,
  faIdCard,
  faUndo,
  faBagShopping,
  faWallet,
  faCalendarDays,
  faBan,
  faExclamationTriangle,
  faScissors,
} from '@fortawesome/free-solid-svg-icons';

export default [
  faCartShopping,
  faChevronDown,
  faChevronUp,
  faSearch,
  faTimesCircle,
  faTimes,
  faMagnifyingGlass,
  faXmarkCircle,
  faXmark,
  faEye,
  faEyeSlash,
  faFilter,
  faTrash,
  faMinus,
  faPlus,
  faChevronRight,
  faChevronLeft,
  faStore,
  faEnvelope,
  faHouse,
  faCircleQuestion,
  faCheck,
  faArrowsRotate,
  faPhone,
  faPhoneAlt,
  faTruckFast,
  faCamera,
  faStar,
  faBell,
  faBug,
  faCircleExclamation,
  faTriangleExclamation,
  faUser,
  faCircleCheck,
  faBars,
  faSignal,
  faListCheck,
  faMapMarkedAlt,
  faBarcode,
  faQrcode,
  faHeart,
  faAddressCard,
  faMapMarkerAlt,
  faList,
  faBookmark,
  faArrowLeft,
  faMoneyBillWave,
  faPencilAlt,
  faSquareCheck,
  faMagic,
  faLeaf,
  faGift,
  faBackspace,
  faEllipsis,
  faPaperPlane,
  faRightLeft,
  faClock,
  faSliders,
  faGlobe,
  faRightToBracket,
  faAddressBook,
  faClockRotateLeft,
  faDoorOpen,
  faIdCard,
  faUndo,
  faBagShopping,
  faWallet,
  faCalendarDays,
  faBan,
  faExclamationTriangle,
  faScissors,
];
