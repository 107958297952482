import { BigNumber } from 'bignumber.js';
import TypedDefaultPk from '@/models/entities/typed-default-pk';

export default {
  methods: {
    /**
     * Get the quantity of an item or the average weight if sold by average weight.
     * @param {Object} item The item.
     * @param {BigNumber} quantity The quantity of `item`.
     * @return {BigNumber}
     */
    getOrderedQuantity(item, quantity) {
      if (this.isSoldByAverageWeight(item)) {
        return new BigNumber(item.averageWeight);
      }
      return quantity;
    },

    /**
     * Get weightedItemQuantity of an item. For random weight items, this is simply the weightedItemQuantity.
     * For average weight items, this is derived from calculating the "each".
     * @param {Object} item The item.
     * @param {BigNumber} quantity The quantity of `item`.
     * @param {BigNumber} weightedItemQuantity The weightedItemQuantity of `item`.
     * @return {BigNumber}
     */
    getWeightedItemQuantity(item, quantity, weightedItemQuantity) {
      if (this.isSoldByAverageWeight(item)) {
        return this.getEachCountFromQuantity(item, quantity);
      }
      return weightedItemQuantity;
    },

    /**
     * Get the weight profile abbreviation for an item.
     * For average weight items, is '{abbrv} / ea avg'.
     * For random weight items, this is '{abbrv} / ea'.
     * For items with no weightedItemQuantity, this is {abbrv}.
     * @param {Object} item The item.
     * @param {BigNumber} quantity The quantity of `item`.
     * @param {BigNumber} weightedItemQuantity The weightedItemQuantity of `item`.
     * @return {String}
     */
    getItemAbbreviation(item, quantity, weightedItemQuantity) {
      const itemWeightedItemQuantity = this.getWeightedItemQuantity(
        item,
        quantity,
        weightedItemQuantity,
      );
      if (itemWeightedItemQuantity && !itemWeightedItemQuantity.isNaN()) {
        if (this.isSoldByAverageWeight(item)) {
          return `${item.weightProfile.abbrv} / ea avg`;
        }
        return `${item.weightProfile.abbrv} / ea`;
      }
      return item.weightProfile.abbrv;
    },

    /**
     * Get the "each" count of an item sold by average weight given the quantity.
     * @param {Object} item The item.
     * @param {BigNumber} quantity The quantity of `item`.
     * @return {BigNumber}
     */
    getEachCountFromQuantity(item, quantity) {
      if (!this.isSoldByAverageWeight(item)) {
        throw new Error('Item count is not defined for items not sold by average weight');
      }
      const averageWeight = new BigNumber(item.averageWeight);
      return quantity.dividedBy(averageWeight).integerValue(BigNumber.ROUND_HALF_UP);
    },

    /**
     * Check if an item is an ASC item.
     *
     * @param {Object} item The item.
     * @return {boolean}
     */
    isAdditionalScanCode(item) {
      try {
        const id = new TypedDefaultPk(item.id);
        return id.isAdditionalScanCode();
      } catch (error) {
        console.error(error);
        return false;
      }
    },

    /**
     * Check if an item is sold by random weight.
     *
     * @param {Object} item The item.
     * @return {boolean}
     */
    isSoldByRandomWeight(item) {
      return this.isSoldByWeight(item) && !this.isSoldByAverageWeight(item);
    },

    /**
     * Check if an item is sold by average weight.
     * @param {Object} item The item.
     * @return {boolean}
     */
    isSoldByAverageWeight(item) {
      if (this.isAdditionalScanCode(item)) {
        return false;
      }
      return item.averageWeight >= 0;
    },

    /**
     * Check if an item is sold by weight and has a weight profile abbreviation.
     * @param {Object} item The item.
     * @return {boolean}
     */
    hasWeightAbbreviation(item) {
      return this.isSoldByWeight(item) && item.weightProfile.abbrv;
    },

    /**
     * Check if an item is sold by weight.
     * @param {Object} item The item.
     * @return {boolean}
     */
    isSoldByWeight(item) {
      if (this.isAdditionalScanCode(item)) {
        return false;
      }
      return Boolean(item.weightProfile);
    },
  },
};
